import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { FacebookEmbed } from 'react-social-media-embed';

const SocialMedia = () => {
  const [socialData, setSocialData] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    // Load social data
    const loadSocialData = async () => {
      try {
        const response = await fetch('/assets/social.json');
        const data = await response.json();
        setSocialData(data);
      } catch (error) {
        console.error('Error loading social media data:', error);
      }
    };

    loadSocialData();
  }, []);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const scrollAmount = 350;
    const newPosition = scrollPosition + (direction === 'left' ? -scrollAmount : scrollAmount);
    
    container.scrollTo({
      left: newPosition,
      behavior: 'smooth'
    });
    
    setScrollPosition(newPosition);
  };

  if (!socialData) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <section className="relative py-16 bg-gray-50" id="social">
      <div 
        className="absolute inset-0 bg-center bg-no-repeat opacity-20"
        style={{
          backgroundImage: `url('assets/bg_3.jpg')`,
          backgroundAttachment: 'fixed',
        }}
      />
      
      <div className="relative container mx-auto px-4">
        <h2 className="text-3xl font-medium text-center mb-12">Connect With Us</h2>
        
        <div className="relative max-w-7xl mx-auto">
          <button
            onClick={() => scroll('left')}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 z-10 
                     bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors
                     focus:outline-none focus:ring-2 focus:ring-blue-500 hidden md:block"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          
          <button
            onClick={() => scroll('right')}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 
                     bg-white rounded-full p-2 shadow-lg hover:bg-gray-100 transition-colors
                     focus:outline-none focus:ring-2 focus:ring-blue-500 hidden md:block"
          >
            <ChevronRight className="w-6 h-6" />
          </button>

          <div 
            ref={scrollContainerRef}
            className="overflow-x-auto scrollbar-hide flex gap-6 pb-8"
          >
            {socialData.socialPosts.map((post) => (
              <div 
                key={post.id}
                className="flex-none bg-white rounded-lg shadow-lg p-4"
              >
                <FacebookEmbed 
                  url={post.embedUrl} 
                  width={350}
                />
              </div>
            ))}
          </div>
        </div>
        
        <div className="mt-12 flex justify-center space-x-6">
          <a 
            href="https://www.facebook.com/profile.php?id=61562003723548"
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 transition-colors"
            aria-label="Visit our Facebook page"
          >
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path 
                fillRule="evenodd" 
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" 
                clipRule="evenodd" 
              />
            </svg>
          </a>
        </div>
      </div>

      <style jsx global>{`
        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </section>
  );
};

export default SocialMedia;