import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

const Logo = () => (
  <div className="flex flex-col items-center">
    <img src="assets/hero.png" alt="Logo" className="h-24 overflow-visible" />
    <span className="text-[#238380] font-medium text-sm -mt-4">Micro School</span>
  </div>
);

const NavItem = ({ href, children, onClick }) => (
  <a 
    href={href}
    onClick={onClick}
    className="flex items-center px-4 py-3 text-base font-medium text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
  >
    {children}
  </a>
);

const MobileMenu = ({ isOpen, onClose }) => {
  // Prevent scrolling when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 z-50 lg:hidden transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
      {/* Backdrop */}
      <div 
        className={`fixed inset-0 bg-gray-900/50 backdrop-blur-sm transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}
        onClick={onClose}
      />
      
      {/* Menu Panel */}
      <div className={`fixed inset-y-0 right-0 w-full max-w-sm bg-white shadow-lg px-6 py-6 transform transition-transform duration-300 ease-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="flex items-center justify-between mb-8">
          <Logo />
          <button
            onClick={onClose}
            className="rounded-lg p-2 hover:bg-gray-100"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <nav className="flex flex-col gap-2">
          <NavItem href="#mission" onClick={onClose}>Our Mission</NavItem>
          <NavItem href="#about" onClick={onClose}>Meet Sally</NavItem>
          <NavItem href="#gallery" onClick={onClose}>Gallery</NavItem>
          <NavItem href="#contact" onClick={onClose}>Contact</NavItem>
        </nav>

        <div className="mt-8 pt-8 border-t border-gray-200">
          <a 
            href="#contact"
            onClick={onClose}
            className="flex items-center justify-center px-4 py-2 rounded-lg 
              bg-[#238380] text-white font-medium hover:bg-[#1a6964] 
              transition-colors w-full"
          >
            Get in Touch
          </a>
        </div>
      </div>
    </div>
  );
};

const MobileMenuButton = ({ onClick }) => (
  <button 
    onClick={onClick}
    className="flex size-12 items-center justify-center self-center rounded-lg 
      hover:bg-black/5 lg:hidden"
    aria-label="Open main menu"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
      <path fillRule="evenodd" d="M3 9a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 9Zm0 6.75a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" />
    </svg>
  </button>
);

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="pt-12 sm:pt-16">
      <div className="relative flex justify-between items-center">
        <div/>

        <nav className="hidden lg:flex items-center gap-4">
          <NavItem href="#mission">Our Mission</NavItem>
          <NavItem href="#about">Meet Sally</NavItem>
          <NavItem href="#gallery">Gallery</NavItem>
          <NavItem href="#contact">Contact</NavItem>
        </nav>

        <MobileMenuButton onClick={() => setMobileMenuOpen(true)} />
        <MobileMenu 
          isOpen={mobileMenuOpen} 
          onClose={() => setMobileMenuOpen(false)} 
        />
      </div>
    </header>
  );
};

const Hero = () => {
  return (
    <div className="relative">
      <div className="absolute inset-2 bottom-0 rounded-4xl ring-1 ring-inset ring-black/5 
        bg-[linear-gradient(115deg,var(--tw-gradient-stops))] 
        from-[#238380] from-[20%] via-[#87CEEB] via-[60%] to-[#4682B4] 
        sm:bg-[linear-gradient(145deg,var(--tw-gradient-stops))]"
      />
      
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-7xl">
          <Header />
          
          <div className="flex flex-col lg:flex-row items-center pb-24 pt-16 sm:pb-32 sm:pt-24 md:pb-48 md:pt-32 gap-12">
            {/* Image Section */}
            <div className="w-full lg:w-1/2 mb-12 lg:mb-0 flex flex-col items-center relative">
              <div className="absolute inset-0 bg-white/75 backdrop-blur-xl rounded-3xl transform -rotate-1"></div>
              <div className="relative w-full p-6">
                <div className="aspect-square overflow-hidden w-full rounded-2xl">
                  <img 
                    src="assets/hero.png"
                    alt="Hero"
                    className="w-full h-full object-cover"
                  />
                </div>
                <span className="text-[#238380] font-bold text-xl mt-4 block text-center -mt-18 lg:-mt-36">
                  MICRO SCHOOL
                </span>
              </div>
            </div>

            {/* Text Section */}
            <div className="w-full lg:w-1/2 lg:pl-12 relative">
              <div className="absolute inset-0 bg-white/50 backdrop-blur-xl rounded-3xl transform rotate-1"></div>
              <div className="relative p-6">
                <h1 className="font-display text-balance text-6xl/[0.9] font-medium tracking-tight 
                  text-gray-950 sm:text-8xl/[0.8] md:text-9xl/[0.8]"
                >
                  Small School, Big Impact
                </h1>
                
                <p className="mt-8 max-w-lg text-xl/7 font-medium text-gray-950/75 sm:text-2xl/8">
                  Transforming Lives Through Christ-Centered Education in a Safe and Nurturing Environment
                </p>
                
                <div className="mt-12 flex flex-col gap-x-6 gap-y-4 sm:flex-row">
                  <a 
                    href="#contact"
                    className="inline-flex items-center justify-center px-4 py-2 
                      rounded-full bg-gray-950 text-white hover:bg-gray-800 
                      transition-colors text-base font-medium"
                  >
                    Reach Out
                  </a>
                  
                  <a 
                    href="#social-posts"
                    className="inline-flex items-center justify-center px-4 py-2 
                      rounded-full bg-white/15 text-gray-950 hover:bg-white/20 
                      transition-colors text-base font-medium ring-1 ring-gray-950/10"
                  >
                    Social Posts
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Header, Hero };